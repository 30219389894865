import { ShiftType } from "../../Shift/constants";
import { type OpenShift } from "../../Shift/Open/types";
import { resolveShiftType } from "../../Shift/resolveShiftType";

export function isPriorityShift(shift: OpenShift) {
  const shiftType = resolveShiftType({
    isUrgent: shift.attributes.isUrgent,
    priorityTill: shift.attributes.priorityTill,
    window: shift.attributes.window,
  });

  return shiftType === ShiftType.PRIORITY || shiftType === ShiftType.A_TEAM;
}

import { convertToGeoLocation, type GeoLocation } from "@src/appV2/Location";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import type { QueryClient } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import { useCallback, useMemo } from "react";

import { getIncludedOpenShiftsData } from "../../Shift/Open/getIncludedOpenShiftsData";
import { type GetOpenShiftsResponse } from "../../Shift/Open/useGetOpenShifts";
import {
  invalidateGetPaginatedOpenShiftsByArea,
  useGetPaginatedOpenShiftsByArea,
} from "../../Shift/Open/useGetPaginatedOpenShiftsByArea";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";
import { isPriorityShift } from "../PriorityShifts/isPriorityShift";
import { useUrgentShiftsData } from "../UrgentShifts/useUrgentShiftsData";
import { getMapViewWorkplaces } from "./getMapViewWorkplaces";
import { sortMapViewWorkplaces } from "./sortMapViewWorkplaces";

interface UseMapWorkplacesDataProps {
  geoLocation?: GeoLocation;
  shouldFetchUrgentShifts?: boolean;
}

export async function invalidateMapViewWorkplacesData(queryClient: QueryClient): Promise<void> {
  await invalidateGetPaginatedOpenShiftsByArea(queryClient);
}

/**
 * Fetches open shifts and workplaces data and returns a sorted list of workplaces
 * that have open shifts within the user's distance filter.
 *
 * Using pagination to fetch data to prevent previous results from being discarded.
 * This way, workplaces already fetched will remain visible when the user
 * moves the map around.
 */
export function useMapViewWorkplacesData(props: UseMapWorkplacesDataProps) {
  const { geoLocation, shouldFetchUrgentShifts = true } = props;

  const worker = useDefinedWorker();
  const coordinates =
    geoLocation ?? convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  const areQueriesEnabled = coordinates.latitude !== 0 && coordinates.longitude !== 0;

  const {
    data: paginatedOpenShiftsData,
    isFetching: isFetchingOpenShiftsData,
    isError: isErrorOpenShiftsData,
    isSuccess: isSuccessOpenShiftsData,
    fetchNextPage: fetchNextOpenShiftsPage,
  } = useGetPaginatedOpenShiftsByArea(
    {
      initialArea: {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        radiusInMiles: DEFAULT_DISTANCE_IN_MILES_FILTER,
      },
      dateRange: getShiftDiscoveryDefaultDateRange(),
      include: ["workplace"],
    },
    {
      enabled: areQueriesEnabled,
    }
  );

  // The open shifts data doesn't include all urgent shifts, so we need to fetch them separately
  const { shifts: urgentShifts } = useUrgentShiftsData({
    enabled: areQueriesEnabled && shouldFetchUrgentShifts,
  });

  const openShiftsData: GetOpenShiftsResponse | undefined = useMemo(() => {
    if (!paginatedOpenShiftsData) {
      return {
        data: [],
        included: [],
      };
    }

    const openShifts = paginatedOpenShiftsData.pages.flatMap((page) => page.data);

    return {
      data: uniqBy(openShifts, (shift) => shift.id),
      included: paginatedOpenShiftsData.pages.flatMap((page) => page.included),
    };
  }, [paginatedOpenShiftsData]);

  const sortedMapViewWorkplaces = useMemo(() => {
    const { workplacesMap } = getIncludedOpenShiftsData(openShiftsData);
    const mapViewWorkplaces = getMapViewWorkplaces(openShiftsData.data, workplacesMap);
    return sortMapViewWorkplaces(mapViewWorkplaces);
  }, [openShiftsData]);

  const fetchMore = useCallback(
    (coordinates: GeoLocation, distanceInMiles: number = DEFAULT_DISTANCE_IN_MILES_FILTER) => {
      const area = { ...coordinates, radiusInMiles: distanceInMiles };
      if (!isFetchingOpenShiftsData) {
        void fetchNextOpenShiftsPage({ pageParam: area });
      }
    },
    [fetchNextOpenShiftsPage, isFetchingOpenShiftsData]
  );

  const priorityShifts = openShiftsData.data.filter((shift) => isPriorityShift(shift));
  const priorityShiftsCount = priorityShifts.length;

  const urgentShiftsCount = shouldFetchUrgentShifts
    ? urgentShifts.length
    : openShiftsData.data.filter((shift) => shift.attributes.isUrgent).length;

  const lastAreaFilterUsed = paginatedOpenShiftsData?.pages.at(-1)?.area;
  const geoLocationFilter: GeoLocation = lastAreaFilterUsed ?? coordinates;
  const distanceFilter: number =
    lastAreaFilterUsed?.radiusInMiles ?? DEFAULT_DISTANCE_IN_MILES_FILTER;

  return {
    data: {
      mapViewWorkplaces: sortedMapViewWorkplaces,
      urgentShiftsCount,
      priorityShiftsCount,
      distanceFilter,
      geoLocationFilter,
    },
    isLoading: isFetchingOpenShiftsData,
    isError: isErrorOpenShiftsData,
    isSuccess: isSuccessOpenShiftsData,
    fetchMore,
  };
}

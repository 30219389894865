import { Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import pluralize from "pluralize";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { invalidateShifts } from "../../invalidateShifts";
import { VirtualShiftListContextProvider } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { PullToRefresh } from "../ListView/PullToRefresh";
import { SHIFT_DISCOVERY_PRIORITY_PATH } from "../paths";
import {
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM,
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT,
} from "../SearchModeSwitcher";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { PriorityShiftsList } from "./List";
import { usePriorityShiftsData } from "./usePriorityShiftsData";

export function ShiftDiscoveryPriorityShiftsPage() {
  const queryClient = useQueryClient();
  const stackRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const { setBaseNavigationPathOverride } = useShiftModalsDataContext();

  /**
   * When this page is opened, we want to override the base navigation path
   * so that when shift modals are opened from here, they are rendered on top of this page
   */
  useEffect(() => {
    setBaseNavigationPathOverride(SHIFT_DISCOVERY_PRIORITY_PATH);
    return () => {
      setBaseNavigationPathOverride(undefined);
    };
  }, [setBaseNavigationPathOverride]);

  const {
    shifts: priorityShifts,
    isError,
    isLoading,
    isSuccess,
    workplacesMap,
  } = usePriorityShiftsData();

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.primary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <VirtualShiftListContextProvider shifts={priorityShifts} workplacesMap={workplacesMap}>
        <PullToRefresh
          scrollRef={stackRef}
          onRefresh={async () => {
            await invalidateShifts(queryClient);
          }}
        />

        <Stack
          ref={stackRef}
          data-testid="priority-shifts-page-scroll-container"
          spacing={7}
          sx={(theme) => ({
            flex: 1,
            overflow: "auto",
            px: 5,
            pt: 6,
            pb: `calc(
          ${theme.spacing(6)} +
          ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT)} +
          ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM)}
        )`,
          })}
        >
          <PageHeaderWrapper>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              position="relative"
              height={(theme) => theme.spacing(10)}
              px={3}
            >
              <Stack position="absolute" left={(theme) => theme.spacing(3)}>
                <IconButton
                  iconType="arrow-left"
                  variant="outlined"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </Stack>
              <Title semibold component="h2" variant="h6">
                {priorityShifts.length > 0 ? priorityShifts.length : ""} Priority{" "}
                {pluralize("Shift", priorityShifts.length)}
              </Title>
            </Stack>
          </PageHeaderWrapper>

          <PriorityShiftsList
            isEmpty={priorityShifts.length === 0}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            workplacesMap={workplacesMap}
            scrollRef={stackRef}
          />
        </Stack>
      </VirtualShiftListContextProvider>
    </Stack>
  );
}

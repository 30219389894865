import { convertToGeoLocation } from "@src/appV2/Location/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";

import { useGetOpenShiftsForDates } from "../../Shift/Open/useGetOpenShiftsForDates";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { isPriorityShift } from "./isPriorityShift";

export function usePriorityShiftsData() {
  const { dates: dateStrings, distance } = useShiftDiscoveryUserFiltersContext();
  const dates = dateStrings.map((dateString) => parseISO(dateString));

  const worker = useDefinedWorker();
  const coordinates = convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  const { shifts, ...rest } = useGetOpenShiftsForDates({
    dates,
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      area: {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        radiusInMiles: distance,
      },
    },
    enabled: worker.geoLocation?.coordinates.length === 2,
  });

  const priorityShifts = shifts.filter((shift) => isPriorityShift(shift));

  return {
    ...rest,
    shifts: priorityShifts,
  };
}
